/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { ResponsiveGrid } from '@adobe/aem-react-editable-components';
import { ApolloProvider } from '@apollo/client';
import { PageParamsContext } from '@marriott/mi-ppv5-components';
import { getDeployedEnvType, useNextMiApolloClient } from '@marriott/mi-apollo-client-utils';
import { Layout, respGridUtil, getProcessEnvs, prePageCall } from '@marriott/mi-headless-utils';
import { constants } from '@marriott/mi-ppv5-components/constants';
import { GetServerSideProps } from 'next';
import { inspect } from 'util';
import { userStates } from './../../../_constants/states';
import { mockModel } from '../../../_localDevMocks/mockModel';
import operationSignatures from '../../../_constants/operationSignatures.json';

const {
  NEXT_PUBLIC_AEM_PATH,
  INVALID_REDIRECT_URL,
  NEXT_PUBLIC_PRE_PROCESSING_PPV5_URL,
  IS_LOCAL_DEV,
  NEXT_PUBLIC_APP_NAME,
} = getProcessEnvs();
const { ITEM_PATH_ROOT } = constants;

// final data would have to come from 2 calls:
// 1. Page level call from AEM - that will populate the labels on components
// 2. Functional data for components like list of amenities and other related information
// import jsonContent from '../../mock/label-info.json';

export default function PropertyPortalPage({
  model,
  deployedEnvType,
  isAuthorMode,
  headersData,
  requestId,
  currentLocale,
  lang,
  preProcessorResponse,
}) {
  const MiApolloClient = useNextMiApolloClient(operationSignatures, deployedEnvType);

  const responsiveGridModel = respGridUtil(model?.cqItems?.root?.[':items']?.responsivegrid);
  const { metaNames, metaProperties, canonical, staticComponentClientLibsJS, staticComponentClientLibsCSS, title } =
    model;
  const context = {
    sessionID: headersData.sessionid,
    preProcessorResponse: preProcessorResponse ?? {},
    requestId,
    currentLocale,
    lang: preProcessorResponse?.locale ?? lang,
    localePath: preProcessorResponse?.subDirectory,
    direction: model?.rtlEnable === 'true' ? 'rtl' : 'ltr',
  };

  return (
    <ApolloProvider client={MiApolloClient}>
      <PageParamsContext.Provider value={context}>
        <Layout
          metaNames={metaNames}
          metaProps={metaProperties}
          title={title}
          staticComponentClientLibsCSS={staticComponentClientLibsCSS}
          staticComponentClientLibsJS={staticComponentClientLibsJS}
          isAuthorMode={isAuthorMode}
          gridCss=""
          canonical={canonical}
          mainID="marriott-main-content"
        >
          <ResponsiveGrid
            {...responsiveGridModel}
            model={model}
            pagePath={NEXT_PUBLIC_AEM_PATH}
            itemPath={ITEM_PATH_ROOT}
          />
        </Layout>
      </PageParamsContext.Provider>
    </ApolloProvider>
  );
}

//Any product specific page data apart from common data like session, datalayer
//can be returned from this function and the same is passed as props to the page Component
const ppv5SpecificData = async context => {
  const { log } = global.loggerInstance('PPv5Preprocessor:');
  //any page specific code can be written here.
  const deployedEnvType = getDeployedEnvType();
  const error: Record<string, any> = {};
  let preprocessorURL = NEXT_PUBLIC_PRE_PROCESSING_PPV5_URL;
  let response: Record<string, any> = {};
  const query = new URLSearchParams(context.query).toString();
  const state = context.query.state;
  const user = context.query.user;
  let EXPERIENCE_SEGMENT = [];

  const sampleResponse = {
    userSegment: 'NOT Logged In',
    validMarsha: true,
    status: 'SUCCESS',
    message: '',
    statusCode: '200',
    brandCode: 'mc',
    experienceSegment: 'In_Stay-Unknown',
    isCNA: false,
    isSameTab: true,
    isMAC: true,
  };

  if (IS_LOCAL_DEV?.toString() !== 'true' && preprocessorURL) {
    try {
      const preHeader: Record<string, string> = {
        Cookie: `sessionID=${context.req?.cookies?.sessionID};UserIdToken=${
          context.req?.cookies?.UserIdToken ? context.req?.cookies?.UserIdToken : ''
        };RememberMeUserID=${encodeURIComponent(
          context.req?.headers?.remembermeuserid ? context.req?.headers?.remembermeuserid : ''
        )};RememberMeAlternateID=${encodeURIComponent(
          context.req?.headers?.remembermealternateid ? context.req?.headers?.remembermealternateid : ''
        )};RememberMeFlag=${context.req?.headers?.remembermeflag ? context.req?.headers?.remembermeflag : ''};`,
        'User-agent': context?.req?.headers?.['user-agent'],
        'Accept-Language': context?.req?.headers['accept-language'] ?? 'en-US',
        'X-Host': context?.req?.headers['x-host'] ?? '',
      };
      log.debug(inspect(`[PPv5-LOGS] PPv5 PREPROCESSOR URL:${preprocessorURL}`));
      log.debug(inspect(`[PPv5-LOGS] PPv5 PREPROCESSOR Headers:${inspect(preHeader)}`));
      preprocessorURL = `${preprocessorURL}?${query}`;
      const preProcessorResponse = await fetch(preprocessorURL, {
        method: 'GET',
        headers: preHeader,
      });

      log.debug(inspect(`[PPv5-LOGS] PPv5 PREPROCESSOR RESPONSE:${inspect(preProcessorResponse)}`));
      log.debug(inspect(`[PPv5-LOGS] PPv5 PREPROCESSOR RESPONSE:${inspect(preProcessorResponse?.data)}`));
      if (preProcessorResponse.status === 200) {
        response = await preProcessorResponse.json();
        response.marsha = context?.query?.index;
      }
      if (!response?.validMarsha || !response?.experienceSegment) {
        log.error(inspect(`[PPv5-LOGS] PPv5 PREPROCESSOR RESPONSE DATA:${inspect(preProcessorResponse?.data)}`));
        error.value = true;
        error.redirect = {
          redirect: {
            permanent: false,
            destination: INVALID_REDIRECT_URL,
          },
        };
        return {
          operationSignatures: operationSignatures,
          deployedEnvType,
          error: error,
          preProcessorResponse: response ?? {},
          EXPERIENCE_SEGMENT,
          appId: response?.appId ?? NEXT_PUBLIC_APP_NAME ?? '',
        };
      }
      EXPERIENCE_SEGMENT.push(response.userSegment);
    } catch (error: any) {
      log.error(inspect(`[PPv5-LOGS] PPv5 PREPROCESSOR ERROR RESPONSE:${inspect(error)}`));
      error.value = true;
      error.redirect = {
        redirect: {
          permanent: false,
          destination: INVALID_REDIRECT_URL,
        },
      };
      return {
        operationSignatures: operationSignatures,
        deployedEnvType,
        error: error,
        preProcessorResponse: response ?? {},
        EXPERIENCE_SEGMENT,
        appId: response?.appId ?? NEXT_PUBLIC_APP_NAME ?? '',
      };
    }
  } else {
    response = sampleResponse;
    EXPERIENCE_SEGMENT.push(response.userSegment);
  }
  if (state && user) {
    response.experienceSegment = state;
    response.userSegment = user;
    EXPERIENCE_SEGMENT = [];
    EXPERIENCE_SEGMENT.push(response.userSegment);
  }

  if (response.isCNA) {
    EXPERIENCE_SEGMENT.push('CNA');
  }

  const states = ['in_stay', 'preconnect', 'connected', 'upgraded', 'upgrade'];
  const experienceSegment = response?.experienceSegment.toLowerCase();
  let updatedState = '';

  for (let i = 0; i < states.length; i++) {
    if (experienceSegment?.includes(states[i])) {
      updatedState = states[i];
      break;
    }
  }

  response.state = updatedState;
  response.userState = userStates.find(i => {
    return response.experienceSegment?.toLowerCase().includes(i.toLowerCase());
  });

  response.cookie = `sessionID=${context.req?.cookies?.sessionID}`;
  response.headersData = context.req?.headers;

  return {
    operationSignatures: operationSignatures,
    deployedEnvType,
    error: error,
    preProcessorResponse: response,
    EXPERIENCE_SEGMENT,
    appId: response?.appId ?? NEXT_PUBLIC_APP_NAME ?? '',
  };
};

//Make pre page calls like session, datalayer, fetch model.json, producSpecifc data etc
//at server side and pass the data is passed as props to page component.
export const getServerSideProps: GetServerSideProps<any> = prePageCall(ppv5SpecificData, mockModel);
